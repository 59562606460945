<template>
   <v-card elevation="0">
      <v-sheet outlined class="pa-4">
         <canvas ref="bar" height="320" />
      </v-sheet>
      <v-card-actions>
         <div class="text-h6 font-weight-thin">
            {{ title }}
         </div>
         <v-spacer />
         <!--
         <v-btn icon @click="$emit('random')">
            <v-icon>
               mdi-table
            </v-icon>
         </v-btn>
         <v-btn icon @click="$emit('append')">
            <v-icon>
               mdi-table-plus
            </v-icon>
         </v-btn>
         -->
         <!--
         <v-btn icon>
            :disabled="excel.importing" @click="$refs.chartFile.click()"
            <v-icon>
               mdi-table-arrow-up
            </v-icon>
            <v-progress-circular v-if="excel.importing" :size="18" width="1" indeterminate color="primary" style="position: absolute;" />
            <input ref="chartFile" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" alt="chartFile" style="display:none;" @change="attachmentPreview">
         </v-btn>
         -->
         <v-btn v-if="$hasRole('presence', 'P')" icon @click="presenceSummary()">
            <!-- :disabled="excel.exporting" @click="$emit('export', (excel.exporting = true))" -->
            <v-icon>
               mdi-table-arrow-down
            </v-icon>
            <!-- <v-progress-circular v-if="excel.exporting" :size="18" width="1" indeterminate color="primary" style="position: absolute;" /> -->
         </v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
import { pastel } from '@/utils/nirtara'
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables)
export default {
   name: 'NBarPresence',

   props: {
      records: { type: Array },
      sample: { type: Array },
      title: { type: String, default: 'Bar Chart' },
      excel: { type: Object },
   },
   data: () => ({
      chart: {},
   }),

   watch: {
      records: function (val) {
         // console.log(`watch ( this.records.length = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         if (this.chart.data.datasets.length) {
            const bar = this.records.find(j => j.type === 'Bar')
            const datasets = this.chart.data.datasets
            datasets.map((j, i) => { j.data = bar.result.map(b => b[j.key]) })
            const labels = bar.result.map(j => (j.day = this.$moment(j.date).format('dddd')))
            this.chart.data = { labels, datasets }
            this.chart.update()
         } else this.construct()
      },
      sample: function (val) {
         const datasets = this.chart.data.datasets
         const labels = this.chart.data.labels
         Array.from({ length: datasets.length }, (_, i) => { datasets[i].data[labels.length - 1] += Math.floor((val[i] || 5) / 5) })
         this.chart.data = { labels: labels, datasets: datasets }
         this.chart.update()
      },
   },
   mounted () {
      // console.log(`mounted ( this.records.length = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
      this.chart = new Chart(this.$refs.bar, {
         type: 'bar',
         options: {
            maintainAspectRatio: false, // , plugins: { legend: { display: false } }
            scales: {
               y: {
                  min: 0,
                  ticks: {
                     callback: (val) => {
                        return Number.isInteger(val) ? val : null
                     },
                  },
               },
            },
         },
      })
      this.construct()
   },
   methods: {
      construct () {
         // console.log(`construct ( this.records.length = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         const bar = this.records.find(j => j.type === 'Bar')
         if (!bar) return

         const labels = bar.result.map(j => (j.day = this.$moment(j.date).format('dddd')))
         const background = pastel(Object.keys(bar.legend).length)
         this.chart.data = {
            labels: labels,
            datasets: Object.keys(bar.legend).map((s, i) => ({
               key: s, // used for updating
               label: bar.legend[s],
               data: bar.result.map(j => j[s]),
               backgroundColor: background[i],
               borderColor: background[i],
               borderWidth: 2,
            })),
         }
         this.chart.update()
      },
      /*
      attachmentPreview (event) {
         this.excel.importing = true
         const reader = new FileReader()
         reader.readAsArrayBuffer(this.$refs[event.target.alt].files[0])
         reader.onload = async () => this.$emit('import', reader.result)
      },
      */
      presenceSummary () {
         const params = { name: 'PresenceSummary', since: this.$moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'), until: this.$moment().format('YYYY-MM-DD 23:59:59') } // type: '', kind: '', , text: ''
         // console.log('presenceSummary (params)', JSON.parse(JSON.stringify(params)))
         this.$router.push({ name: 'Reports', params })
      },
   },
}
</script>
